import React from "react";
import CaseStudyTemplate from "../components/porfoliopage/CaseStudyTemplate";
import DarkLightToggle from "../components/common/DarkLightToggle"
import LoadingAnim from "../components/common/LoadingAnim";

const TestPage = () => {
  return (
    <>
    {/* <div className="bg-theme-white text-theme-black dark:bg-theme-black dark:text-theme-white h-screen w-screen center">
      <LoadingAnim />
    </div> */}
    <div className="bg-theme-white text-theme-black dark:bg-theme-black dark:text-theme-white">
      <CaseStudyTemplate />
    </div>
    </>
  );
};

export default TestPage;
