import React from "react";
import { Link } from "react-router-dom";
import { BaselineAnimText } from "../common/AnimatedText";
import LinkArrow from "../../svg/LinkArrow";

const SingleProjectList = ({
  link,
  title,
  categories,
  imgSrc,
  lgImgSrc,
  darkText,
}) => {
  return (
    <Link
      to={link}
      title="view case"
      onClick={() => {
        window.scroll(0, 0);
      }}
    >
      <div className="projects_list2-single group">
        <div
          className="projects_list2-image-wrapper hidden lg:flex"
          style={{ backgroundImage: `url(${lgImgSrc})` }}
        >
          {/* <img src={imgSrc} className="min-w-full lg:hidden" /> */}
        </div>
        <div
          className="projects_list2-image-wrapper lg:hidden"
          style={{ backgroundImage: `url(${imgSrc})` }}
        >
          {/* <img src={imgSrc} className="min-w-full lg:hidden" /> */}
        </div>
        <div
          className={`projects_list2-text-wrapper ${
            darkText ? "lg:text-theme-black" : "lg:text-theme-white"
          }`}
        >
          <div>
            <div className="text-3xl lg:text-[40px] font-semibold">
              <BaselineAnimText text={title} />
            </div>
            <div className="projects_list2-category ">
              <BaselineAnimText text={categories} />
            </div>
          </div>
          <div className="view-case-btn">
            <LinkArrow />
          </div>
        </div>

        <div className="lg-view-case-btn absolute bottom-10 right-10 w-0 z-20">
          <LinkArrow />
        </div>
      </div>
    </Link>
  );
};

export default SingleProjectList;
