import React from 'react'

const Sun = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    fill="none"
    viewBox="0 0 42 42"
  >
    <path
      className='fill-[#F9635B]'
      d="M21 15a6 6 0 11-6 6 6.007 6.007 0 016-6zm0-3a9 9 0 100 17.999A9 9 0 0021 12zM5.091 7.212l2.124-2.121 5.258 5.259-2.123 2.121-5.259-5.259zM0 19.5h7.5v3H0v-3zm5.091 15.29l5.259-5.26 2.121 2.121-5.258 5.26-2.122-2.122zM19.5 34.5h3V42h-3v-7.5zm10.03-2.85l2.121-2.121 5.26 5.259-2.122 2.121-5.259-5.259zM34.5 19.5H42v3h-7.5v-3zm-4.97-9.152l5.26-5.259 2.12 2.121-5.258 5.26-2.122-2.122zM19.5 0h3v7.5h-3V0z"
    ></path>
  </svg>
  )
}

export default Sun