import React, { useState, useEffect } from "react";
import Sun from "../../svg/Sun";

const DarkLightToggle = () => {
  const [darkMode, setDarkMode] = useState(true);
  useEffect(() => {
    const myHtml = document.getElementById("myHtml");
    if (darkMode) {
      myHtml?.classList.add("dark");
    }
    if (!darkMode) {
      myHtml?.classList.remove("dark");
    }
  });
  return (
    <div
      className={`text-sm lg:text-xl center gap-x-1 lg:gap-x-2 flex-col lg:flex-row`}
    >
      <div className="hidden lg:flex">Dark</div>
      <div
        onClick={() => setDarkMode(!darkMode)}
        className={`relative h-[1.75rem] aspect-[1.45/1] overflow-hidden rounded-3xl cursor-pointer p-[3px] flex flex-col items-start bg-theme-gray bg-opacity-30 ${
          darkMode ? "" : ""
        } ease-in duration-300`}
      >
        <div
          className={`h-full rounded-full aspect-square center ${
            darkMode ? "bg-theme-black " : "bg-transparent translate-x-[55%]"
          } transition duration-300`}
        >
          <div
            className={`relative aspect-square bg-[#FFF3B0] rounded-full ${
              darkMode ? "h-1/2 scale-1" : "scale-2"
            } center transition duration-300`}
          >
            <div
              className={`absolute top-0 right-0 h-[70%] bg-theme-black aspect-square rounded-full  ${
                darkMode ? "scale-1 transition duration-300" : "scale-0"
              } `}
            ></div>

            <div
              className={`w-[70%]  ${
                darkMode ? "scale-0" : "scale-1 transition duration-300"
              } `}
            >
              <Sun />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex">Light</div>
    </div>
  );
};

export default DarkLightToggle;
