import React from "react";

const CSSLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-full group-hover:scale-125 transition"
      fill="none"
      viewBox="0 0 80 80"
    >
      <path
        fill="#264DE4"
        d="M17.15 68.272a1 1 0 01-.729-.875l-5.074-56.914a1 1 0 01.996-1.089h55.314a1 1 0 01.996 1.09l-5.08 56.904a1 1 0 01-.729.874l-22.612 6.27a1 1 0 01-.535 0l-22.547-6.26z"
      ></path>
      <path
        fill="#EBEBEB"
        d="M23.246 35.962l.645 7.198H40v-7.198H23.246zm-.642-7.372H40v-7.198H21.95l.654 7.198zM40 54.657l-.032.008-8.017-2.165-.512-5.74h-7.226l1.008 11.302 14.746 4.093.033-.01v-7.488z"
      ></path>
      <path
        fill="#fff"
        d="M39.975 35.962v7.198h8.864l-.835 9.336-8.029 2.167v7.489l14.757-4.09.108-1.216 1.692-18.951.176-1.933 1.3-14.57H39.974v7.198h10.143l-.655 7.372h-9.488z"
      ></path>
    </svg>
  );
};

export default CSSLogo;
