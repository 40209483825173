import React from "react";
import { Link } from "react-router-dom";

const SocialEmail = () => {
  return (
    <div className="social-icons_wrapper center">
      <Link to="mailto:hello@erikagurung.com" target="_blank" rel="noopener noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="group w-full"
            fill="none"
            viewBox="0 0 50 51"
          >
            <circle
              cx="25"
              cy="25.024"
              r="25"
            ></circle>
            <path
              className="fill-theme-black dark:fill-theme-pure-white"
              d="M16.25 34c-.619 0-1.149-.22-1.59-.661A2.163 2.163 0 0114 31.75v-13.5c0-.619.22-1.149.662-1.59.44-.44.97-.66 1.588-.66h18c.619 0 1.149.22 1.59.662.44.44.66.97.66 1.588v13.5c0 .619-.22 1.149-.661 1.59-.441.44-.971.66-1.589.66h-18zm9-7.875l-9-5.625v11.25h18V20.5l-9 5.625zm0-2.25l9-5.625h-18l9 5.625zm-9-3.375v-2.25 13.5V20.5z"
            ></path>
          </svg>
      </Link>
    </div>
  );
};

export default SocialEmail;
