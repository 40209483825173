import React from "react";
import { easeInOut, motion } from "framer-motion";

export const AnimatedText = ({ text, center, paddingy }) => {
  const sentence = {
    visible: {
      transition: {
        staggerChildren: 0.01,
      },
    },
  };
  const letter = {
    hidden: {
      opacity: 0,
      y: "200%",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
    },
  };

  const splitWords = text.split(" ");

  // Create storage array
  const words = [];

  // Push each word into words array
  for (const [, item] of splitWords.entries()) {
    words.push(item.split(""));
  }

  words.map((word) => {
    return word.push("\u00A0");
  });

  return (
    <motion.div
      variants={sentence}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className={`flex overflow-hidden flex-wrap ${
        center ? "items-center justify-center" : ""
      }`}
      style={{ paddingTop: paddingy, paddingBottom: paddingy }}
    >
      {words.map((word, index) => {
        return (
          // Wrap each word in the Wrapper component
          <div key={index} className="flex flex-nowrap">
            {words[index].flat().map((element, index) => {
              return (
                <span
                  style={{
                    // paddingTop: "10px",
                    // paddingBottom: "10px",
                    // overflow: "hidden",
                    display: "inline-flex",
                  }}
                  key={index}
                >
                  <motion.span
                    style={{ display: "inline-flex" }}
                    variants={letter}
                  >
                    {element}
                  </motion.span>
                </span>
              );
            })}
          </div>
        );
      })}
    </motion.div>
  );
};

export const BaselineAnimText = ({ text, paddingy }) => {
  return (
    <div className="overflow-hidden">
      <motion.div
        initial={{ y: "80%" }}
        whileInView={{ y: 0 }}
        transition={{ ease: [0.455, 0.03, 0.515, 0.955], duration: 0.3 }}
        style={{ paddingTop: paddingy, paddingBottom: paddingy }}
        dangerouslySetInnerHTML={{__html:text}}
      >
        {/* {text} */}
      </motion.div>
</div>
  );
};
