import React from "react";

function CodeLeftBracket() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-full opacity-50"
      fill="none"
      viewBox="0 0 101 200"
    >
      <rect
        width="18"
        height="70.468"
        x="67.999"
        y="49.818"
        className="fill-theme-gray"
        rx="7.661"
        transform="rotate(46.98 67.999 49.818)"
      ></rect>
      <rect
        width="18"
        height="70.468"
        x="17.5"
        y="100.202"
        className="fill-theme-gray"
        rx="7.661"
        transform="rotate(-46.98 17.5 100.202)"
      ></rect>
    </svg>
  );
}

export default CodeLeftBracket;
