import React from 'react'

function DevelopmentPink() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className='w-full'
      fill="none"
      viewBox="0 0 20 150"
    >
      <rect
        width="20"
        height="150"
        fill="url(#paint0_linear_833_770)"
        rx="10"
      ></rect>
      <defs>
        <linearGradient
          id="paint0_linear_833_770"
          x1="10"
          x2="10"
          y1="0"
          y2="150"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF00AD"></stop>
          <stop offset="1" stopColor="#4264E8" stopOpacity="0.9"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DevelopmentPink