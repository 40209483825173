import React from 'react'
import CaseStudyTemplate from "../../components/porfoliopage/CaseStudyTemplate";
import pageData from "../../data/caseStudy/thpt.json";

const THPT = () => {
  return (
    <>
    <CaseStudyTemplate pageData={pageData} />
  </>
  )
}

export default THPT