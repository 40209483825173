import { useState, useEffect } from "react";
import { Route, Routes } from "react-router";
import LoadingAnim from "./components/common/LoadingAnim";
import { ParallaxProvider } from "react-scroll-parallax";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import HomePage from "./pages/HomePage";
import TestPage from "./pages/TestPage";
import ComingSoonPage from "./pages/ComingSoonPage";
import ProjectsPage from "./pages/ProjectsPage";
import IdeaMood from "./pages/caseStudyPages/IdeaMood";
import Blukers from "./pages/caseStudyPages/Blukers";
import Zalchileo from "./pages/caseStudyPages/Zalchileo";
import Learn2Soar from "./pages/caseStudyPages/Learn2Soar";
import THPT from "./pages/caseStudyPages/THPT";
import Delta from "./pages/caseStudyPages/Delta";
import BlackHills from "./pages/caseStudyPages/BlackHills";
import ContactPage from "./pages/ContactPage";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2800);
  }, []);
  return (
    <>
      {loading && <LoadingAnim />}
      {!loading && (
        <div
          id="myHtml"
          className="bg-theme-white text-theme-black dark:bg-theme-black dark:text-theme-white"
        >
          <Header />
          <ParallaxProvider>
            <Routes>
              <Route path="/" element={<HomePage />} />
              {/* <Route path="projects/*" element={<ProjectsPage />}>
                <Route index element={<IdeaMood />}/>
                <Route path="idea-mood" element={<IdeaMood />} />
                <Route path="blukers" element={<Blukers />} />
                <Route path="zalchileo" element={<Zalchileo />} />
                <Route path="learn2soar" element={<Learn2Soar />} />
              </Route> */}
              <Route path="projects" element={<ProjectsPage />} />
              <Route path="idea-mood" element={<IdeaMood />} />
              <Route path="blukers" element={<Blukers />} />
              <Route path="zalchileo" element={<Zalchileo />} />
              <Route path="learn2soar" element={<Learn2Soar />} />
              <Route path="thpt" element={<THPT />} />
              <Route path="delta" element={<Delta />} />
              <Route path="blackhills" element={<BlackHills />} />

              <Route path="contact" element={<ContactPage />}/>
              <Route path="test" element={<TestPage />} />
              <Route path="coming-soon" element={<ComingSoonPage />} />
            </Routes>
          </ParallaxProvider>
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
