import React from "react";

const Javascript = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-full group-hover:scale-125 transition"
      fill="none"
      viewBox="0 0 80 80"
    >
      <path
        fill="#F7DF1E"
        d="M65 5H15C9.477 5 5 9.477 5 15v50c0 5.523 4.477 10 10 10h50c5.523 0 10-4.477 10-10V15c0-5.523-4.477-10-10-10z"
      ></path>
      <path
        fill="#000"
        d="M35.759 39.819h5.471v18.256c0 1.687-.379 3.153-1.138 4.397-.75 1.245-1.794 2.203-3.132 2.877-1.338.673-2.893 1.01-4.666 1.01-1.577 0-3.008-.277-4.295-.831a6.866 6.866 0 01-3.043-2.557c-.75-1.15-1.12-2.595-1.112-4.334h5.51c.017.69.158 1.283.422 1.777.272.486.643.86 1.112 1.125.477.256 1.04.384 1.687.384.682 0 1.258-.145 1.726-.435.477-.298.84-.733 1.087-1.304.247-.571.37-1.274.37-2.11V39.82zm24.251 7.53c-.102-1.031-.541-1.833-1.317-2.404-.775-.57-1.828-.856-3.157-.856-.904 0-1.667.128-2.289.383-.622.247-1.1.593-1.432 1.036a2.493 2.493 0 00-.485 1.508c-.017.47.08.878.294 1.228.221.349.524.652.907.907.384.247.827.465 1.33.652.503.18 1.04.333 1.61.46l2.353.563c1.142.256 2.19.597 3.145 1.023.954.426 1.781.95 2.48 1.572a6.535 6.535 0 011.624 2.199c.392.844.592 1.811.6 2.902-.008 1.602-.417 2.992-1.227 4.168-.801 1.167-1.96 2.075-3.477 2.723-1.509.639-3.328.958-5.459.958-2.114 0-3.954-.323-5.523-.971-1.56-.648-2.778-1.607-3.656-2.877-.87-1.278-1.325-2.859-1.368-4.742h5.357c.06.877.31 1.61.754 2.198.452.58 1.053 1.019 1.803 1.317.758.29 1.615.435 2.57.435.937 0 1.75-.137 2.44-.41.7-.272 1.24-.651 1.624-1.137a2.627 2.627 0 00.576-1.675c0-.588-.175-1.082-.524-1.483-.341-.4-.844-.741-1.509-1.023-.656-.28-1.462-.536-2.416-.767l-2.851-.715c-2.207-.537-3.95-1.377-5.229-2.519-1.278-1.142-1.913-2.68-1.904-4.615-.009-1.585.413-2.97 1.265-4.155.86-1.184 2.041-2.11 3.541-2.774 1.5-.665 3.205-.997 5.114-.997 1.943 0 3.64.332 5.088.997 1.457.665 2.59 1.59 3.4 2.774.81 1.185 1.228 2.557 1.253 4.117H60.01z"
      ></path>
    </svg>
  );
};

export default Javascript;
