import React from "react";
import { motion } from "framer-motion";
import { AnimatedText } from "./AnimatedText";
import Arrow from "../../svg/Arrow";
import { Letter, LetterItalic } from "./AnimatedText";

const LoadingAnim = () => {
  return (
    <div className="fixed overflow-hidden w-screen h-screen center bg-[#FCFCFF]">
      <div className="w-full">
        <img
          src="/img/intro-anim-pink-lg.gif"
          className="min-w-full hidden  lg:flex"
        />
        <img
          src="/img/intro-anim-pink-sm.gif"
          className="min-w-full lg:hidden"
        />
      </div>
    </div>
  );
};

export default LoadingAnim;
