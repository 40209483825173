import React from "react";

function CodeRightBracket() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-full opacity-50"
      fill="none"
      viewBox="0 0 101 200"
    >
      <rect
        width="18"
        height="70.468"
        x="33.102"
        y="150.182"
        className="fill-theme-gray"
        rx="7.661"
        transform="rotate(-133.02 33.102 150.182)"
      ></rect>
      <rect
        width="18"
        height="70.468"
        x="83.602"
        y="99.798"
        className="fill-theme-gray"
        rx="7.661"
        transform="rotate(133.02 83.602 99.798)"
      ></rect>
    </svg>
  );
}

export default CodeRightBracket;
