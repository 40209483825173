import React from "react";
import { Link } from "react-router-dom";

const SocialLinkedIn = () => {
  return (
    <div className="social-icons_wrapper center">
      <Link to="https://www.linkedin.com/in/erika-g-20854521b/" target="_blank" rel="noopener noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="group w-full"
            fill="none"
            viewBox="0 0 50 51"
          >
            <circle cx="25" cy="25.024" r="25"></circle>
            <path
              className="fill-theme-black dark:fill-theme-pure-white"
              fillRule="evenodd"
              d="M35 35h-4.144v-6.496c0-1.55-.026-3.542-2.153-3.542-2.156 0-2.486 1.688-2.486 3.43V35h-4.14V21.64h3.973v1.828h.058c.551-1.05 1.904-2.158 3.92-2.158 4.196 0 4.971 2.766 4.971 6.363V35H35zM17.405 19.817a2.399 2.399 0 01-2.223-1.487 2.41 2.41 0 012.69-3.284 2.403 2.403 0 011.889 1.89 2.413 2.413 0 01-1.02 2.473 2.4 2.4 0 01-1.335.407h-.001zM19.478 35h-4.149V21.64h4.149V35z"
              clipRule="evenodd"
            ></path>
          </svg>
      </Link>
    </div>
  );
};

export default SocialLinkedIn;
