import React from "react";

const Logo = () => {
  return (
    <>
      {/* <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      fill="none"
      viewBox="0 0 71 25"
    >
      <path
        className="fill-theme-black dark:fill-theme-white" 
        d="M8.96 24.416c-1.472 0-2.816-.379-4.032-1.136a8.517 8.517 0 01-2.896-3.04C1.317 18.96.96 17.547.96 16c0-1.173.208-2.267.624-3.28a8.57 8.57 0 011.712-2.688 8.03 8.03 0 012.56-1.824 7.47 7.47 0 013.104-.656c1.227 0 2.352.256 3.376.768a7.69 7.69 0 012.624 2.096 8.618 8.618 0 011.6 3.072c.341 1.152.443 2.379.304 3.68H4.528c.139.736.405 1.403.8 2 .405.587.917 1.05 1.536 1.392.63.341 1.328.517 2.096.528.81 0 1.547-.203 2.208-.608a4.784 4.784 0 001.648-1.68l3.376.784a8.432 8.432 0 01-2.896 3.488c-1.29.896-2.736 1.344-4.336 1.344zm-4.544-9.792h9.088a4.661 4.661 0 00-.816-2.08 4.69 4.69 0 00-1.616-1.488 4.116 4.116 0 00-2.112-.56c-.757 0-1.461.181-2.112.544a4.737 4.737 0 00-1.6 1.488 5.41 5.41 0 00-.832 2.096zM31.826 8h3.328v16h-3.328V8zm1.696-2.32c-.512 0-.938-.165-1.28-.496-.341-.33-.512-.747-.512-1.248 0-.49.17-.901.512-1.232a1.717 1.717 0 011.264-.512c.49 0 .907.17 1.248.512.342.33.512.741.512 1.232 0 .501-.17.917-.512 1.248-.33.33-.741.496-1.232.496zM52.725 24h-3.84l-3.936-6.784-2.576 3.072V24h-3.328V0h3.328v15.12L48.325 8h4.352l-5.456 6.512L52.725 24zm14.08-16h3.328v16H66.79l-.129-2.32a5.564 5.564 0 01-1.904 1.984c-.8.501-1.744.752-2.832.752a8.42 8.42 0 01-3.311-.656 8.75 8.75 0 01-2.736-1.856 8.708 8.708 0 01-1.825-2.72 8.46 8.46 0 01-.655-3.328c0-1.141.213-2.213.64-3.216a8.236 8.236 0 011.776-2.656 8.331 8.331 0 012.623-1.776 7.956 7.956 0 013.217-.656c1.162 0 2.186.267 3.071.8a7.081 7.081 0 012.225 2.016L66.805 8zm-4.96 13.2c.94 0 1.76-.23 2.465-.688a4.684 4.684 0 001.632-1.888c.394-.79.592-1.664.592-2.624 0-.97-.198-1.85-.593-2.64a4.685 4.685 0 00-1.648-1.872c-.693-.47-1.509-.704-2.448-.704-.928 0-1.776.235-2.544.704a5.162 5.162 0 00-1.824 1.888c-.447.79-.671 1.664-.671 2.624 0 .97.229 1.85.688 2.64a5.518 5.518 0 001.84 1.872 4.81 4.81 0 002.511.688z"
      ></path>
      <path
        className="fill-theme-pink"
        d="M18.227 24l2.24-16h3.328l-.224 1.696a7.142 7.142 0 012.256-1.568 6.587 6.587 0 014.448-.336l-1.808 3.36a3.09 3.09 0 00-1.2-.24c-.715 0-1.392.176-2.032.528a4.72 4.72 0 00-1.584 1.392 4.369 4.369 0 00-.8 1.952L21.555 24h-3.328z"
      ></path>
    </svg> */}
      <div className="text-2xl font-semibold text-theme-black dark:text-theme-white">
        e<span className="theme-italic">r</span>ika
      </div>
    </>
  );
};

export default Logo;
