import React from "react";
import { Link } from "react-router-dom";

const SocialUpwork = () => {
  return (
    <div className="social-icons_wrapper center">
    <Link to="https://www.upwork.com/freelancers/~017bba686d6c287b6e" target="_blank" rel="noopener noreferrer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="group w-full"
          fill="none"
          viewBox="0 0 50 51"
        >
          <circle
            cx="25"
            cy="25.024"
            r="25"
          ></circle>
          <path
            className="fill-theme-black dark:fill-theme-pure-white"
            d="M31.42 18.441c-2.553.012-4.494 1.701-5.22 4.392A23.716 23.716 0 0123.483 17l-2.688.013.038 7.123a2.555 2.555 0 11-5.108.024l-.037-7.123L13 17.05l.037 7.124c.01 2.956 2.373 5.298 5.263 5.283 2.891-.015 5.232-2.377 5.219-5.334l-.006-1.21a22.82 22.82 0 001.964 3.217L23.834 34l2.754-.013 1.184-5.718c1.078.668 2.289 1.065 3.7 1.058 2.957-.013 5.365-2.443 5.35-5.535-.013-2.957-2.445-5.365-5.402-5.351zm.038 8.132c-1.075.005-2.152-.46-3.029-1.196l.264-1.08v-.067c.196-1.145.791-3.096 2.807-3.105a2.696 2.696 0 012.701 2.676c-.06 1.546-1.331 2.762-2.743 2.768v.004z"
          ></path>
        </svg>
    </Link>
  </div>
  );
};

export default SocialUpwork;
