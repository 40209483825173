import React from "react";
import CaseStudyTemplate from "../../components/porfoliopage/CaseStudyTemplate";
import pageData from "../../data/caseStudy/delta.json";

const Delta = () => {
  return (
    <>
      <CaseStudyTemplate pageData={pageData} />
    </>
  );
};

export default Delta;
