import React from 'react'

function LinkArrow() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
    //   fill="#1A2B4D"
      data-projection-id="91"
      viewBox="0 0 87.071 87.071"
    //   style={{
    //     WebkitTransformOrigin: "50% 50%",
    //     MsTransformOrigin: "50% 50%",
    //     transformOrigin: "50% 50%",
    //   }}
    >
      <g
        fill="none"
        className='stroke-theme-pure-white' 
        strokeLinecap="round"
        strokeWidth="10"
        data-name="Group 43"
      >
        <path
          d="M80 0H0"
          data-name="Line 9"
          transform="translate(3.536 3.536)"
        ></path>
        <path
          d="M80 80V0"
          data-name="Line 10"
          transform="translate(3.536 3.536)"
        ></path>
        <path
          d="M80 0L0 80"
          data-name="Line 11"
          transform="translate(3.536 3.536)"
        ></path>
      </g>
    </svg>
  )
}

export default LinkArrow