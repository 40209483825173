import React from "react";
import { Link } from "react-router-dom";
import navLinksData from "../../data/nav-links-data.json";

const MobileMenu = ({ setMobileMenuActivated, mobileMenuActivated }) => {
  return (
    <div className="mbl-menu w-screen h-screen text-theme-black dark:text-theme-white bg-theme-white dark:bg-theme-black flex flex-col items-center justify-center gap-y-12 text-4xl">
      {navLinksData.map((item, index) => (
        <Link
          key={item.index}
          to={item.link}
          onClick={() => {
            window.scroll(0, 0);
            setMobileMenuActivated(!mobileMenuActivated);
          }}
          className="link-hover"
        >
          {item.name}
        </Link>
      ))}
    </div>
  );
};

export default MobileMenu;
