import React from 'react'

const ComingSoonPage = () => {
  return (
    <div className='center w-screen h-screen bg-theme-white dark:bg-theme-black text-theme-black dark:text-theme-white'>
        <div className='theme-lgtext main-container font-bold text-center'>Coming Soon</div>
    </div>
  )
}

export default ComingSoonPage