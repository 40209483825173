import React from "react";
import { BaselineAnimText } from "../common/AnimatedText";
import { Parallax } from "react-scroll-parallax";
import { useParallax } from "react-scroll-parallax";
import { Link } from "react-router-dom";
// import pageData from "../../data/caseStudy/ideamood.json";

const CaseStudyTemplate = ({ pageData }) => {
  let parallaxLg = useParallax({
    speed: -30,
  });
  return (
    <div className="w-full min-h-screen bg-theme-white text-theme-black dark:bg-theme-black dark:text-theme-white">
      <div className="pb-12 pt-32 lg:pt-60">
        {/* project name --------------------------------------------------------------------------- */}
        <div className="main-container flex flex-col lg:items-start justify-between">
          <div className="case_study-section1">
            <h1 className="">
              <BaselineAnimText
                text={pageData.projectName}
                paddingy="0.75rem"
              />
            </h1>
            <div className="theme-p1">
              <BaselineAnimText text={pageData.projectCategory} />
            </div>
          </div>
        </div>

        {/* project img desktop ------------------------------------------------------------------------------------ */}
        <div className="case_study-projectImg-lg">
          <div className="absolute top-0 left-0 w-full" ref={parallaxLg.ref}>
            <img src={pageData.projectImg} className="min-w-full" />
          </div>
        </div>

        {/* project img mobile ------------------------------------------------------------------------------------ */}
        <div className="case_study-projectImg-mbl">
          <img src={pageData.projectImg} className="min-w-full" />
        </div>

        <div className="main-container max-w-[1024px] ">
          {/* project overview-------------------------------------------------------------------------- */}
          <div className="py-14 lg:py-32 ">
            <div className="project_overview-container max-w-[800px] flex flex-col gap-y-5 lg:gap-y-10">
              <h2 className="theme-h2 font-semibold">
                <BaselineAnimText text="Overview" />
              </h2>
              <p className="project_overview-about theme-p1">
                {pageData.aboutProject}
              </p>
              <div className="project_overview-table ">
                {/* client name -------------------------------------------------------------------------- */}
                <div className="project_overview-table-row">
                  <div className="project_overview-table-heading ">Client</div>
                  <div className="project_overview-table-desc ">
                    {pageData.client}
                  </div>
                </div>
                {/* client year -------------------------------------------------------------------------- */}
                <div className="project_overview-table-row">
                  <div className="project_overview-table-heading ">Year</div>
                  <div className="project_overview-table-desc ">
                    {pageData.year}
                  </div>
                </div>
                {/* project duration -------------------------------------------------------------------------- */}
                <div className="project_overview-table-row">
                  <div className="project_overview-table-heading ">
                    Project Duration
                  </div>
                  <div className="project_overview-table-desc ">
                    {pageData.duration}
                  </div>
                </div>
                {/* what I did -------------------------------------------------------------------------- */}
                <div className="project_overview-table-row">
                  <div className="project_overview-table-heading ">
                    What I did
                  </div>
                  <div className="project_overview-table-desc ">
                    {pageData.whatIDid}
                  </div>
                </div>
                {/* tech used -------------------------------------------------------------------------- */}
                <div className="project_overview-table-row">
                  <div className="project_overview-table-heading ">
                    Key Technologies Used
                  </div>
                  <div className="project_overview-table-desc ">
                    {pageData.technologiesUsed}
                  </div>
                </div>
              </div>

              {/* live website btn -------------------------------------------------------------------- */}
            </div>
            <Link
              to={pageData.liveProjectLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="linked-btn mt-10">View Project</div>
            </Link>
          </div>

          {/* second img ------------------------------------------------------------------------------------ */}
          <div className="w-full rounded-[25px] overflow-hidden ">
            <img src={pageData.secondSectionImg} className="min-w-full" />
          </div>
          {/* project details --------------------------------------------------------------------------------------- */}
          <div className="project_details py-14 lg:py-32 flex flex-col gap-y-16  theme-p2">
            <div className="about">
              <h3 className="theme-h2 font-semibold pb-5 lg:pb-10">
                <BaselineAnimText text="The Main Idea" />
              </h3>
              <p>{pageData.theMainIdea}</p>
            </div>
            <div className="objectives">
              <h3 className="theme-h2 font-semibold pb-5 lg:pb-10">
                <BaselineAnimText text="Project Objectives" />
              </h3>
              <div
                dangerouslySetInnerHTML={{ __html: pageData.objectives }}
              ></div>
            </div>
            <div className="process">
              <h3 className="theme-h2 font-semibold pb-5 lg:pb-10">
                <BaselineAnimText text="Process" />
              </h3>

              <div dangerouslySetInnerHTML={{ __html: pageData.process }}></div>
            </div>
            <div className="results">
              <h3 className="theme-h2 font-semibold pb-5 lg:pb-10">
                <BaselineAnimText text="Results" />
              </h3>
              <p>{pageData.results}</p>
            </div>
            <div className="feedback">
              <h3 className="theme-h2 font-semibold pb-5 lg:pb-10">
                <BaselineAnimText text="Client Feedback" />
              </h3>
              <p>{pageData.clientFeedback}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyTemplate;
