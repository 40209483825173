import React, { useState } from "react";
import { BaselineAnimText } from "../components/common/AnimatedText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactPage = () => {
  //Contact form
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    services: "",
    company: "",
    message: "",
  });

  //Result if message was sent or not
  const [result, setResult] = useState("");

  //Status of while message is being sent
  const [status, setStatus] = useState("Submit");

  const notifySuccess = () => {
    toast.success("Succesfully submitted!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const notifyError = () => {
    toast.error("Uh oh, there was an error while submitting", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  function resetContactForm() {
    setContactForm({
      name: "",
      email: "",
      services: "",
      company: "",
      message: "",
    });
  }

  function handleContactFormChange(event) {
    setContactForm((prevEmailData) => {
      return {
        ...prevEmailData,
        [event.target.name]: event.target.value,
      };
    });

    if (result.length > 0) {
      setResult("");
    }
  }

  const handleSubmit = async (e) => {
    setResult("");
    e.preventDefault();
    setStatus("Sending...");

    const { name, email, services, company, message } = e.target.elements;

    let details = {
      name: name.value,
      email: email.value,
      services: services.value,
      company: company.value,
      message: message.value,
    };

    try {
      let response = await fetch("https://eriiikaaa-backend.vercel.app/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });
      setStatus("Submit");
      let result = await response.json();

      if (result.status === "success") { 
        notifySuccess();
        setResult('Message Sent!');
        resetContactForm();
      } else if (result.status === "fail") {
        notifyError();
        alert("error")
      }
    } catch (error) {
      // notifyError();
      console.error(error);
      setStatus("Submit"); 
      setResult('Uh oh! Issues with submitting message.');
    }
  };
  return (
    <div className=" bg-theme-white text-theme-black dark:bg-theme-black dark:text-theme-white !overflow-hidden">
      <ToastContainer/>
      <div className="main-container">
        <div className="pb-20 pt-32 lg:pt-60">
          <div className="text-center theme-h1 flex flex-col lg:gap-y-2 items-center font-semibold">
            <h1>
              <BaselineAnimText text="Got A Project?" />
            </h1>
            <h2>
              <BaselineAnimText text="Let’s Collaborate!" />
            </h2>
          </div>

          <form
            id="contact-form"
            className="contact-form w-full flex flex-col gap-y-6 lg:gap-y-8"
            onSubmit={handleSubmit}
            method="post"
          >
            <div className="theme-input">
              <input
                placeholder="name"
                type="text"
                id="name"
                name="name"
                required={true}
                value={contactForm.name}
                onChange={handleContactFormChange}
              ></input>
              <div className="input-underline"></div>
              <label htmlFor="name">
                Name<span className="text-red-500">&nbsp;*</span>
              </label>
            </div>
            <div className="theme-input">
              <input
                placeholder="email"
                type="email"
                id="email"
                name="email"
                required={true}
                value={contactForm.email}
                onChange={handleContactFormChange}
              ></input>
              <div className="input-underline"></div>
              <label htmlFor="email">
                Email<span className="text-red-500">&nbsp;*</span>
              </label>
            </div>
            <div className="theme-input">
              <input
                placeholder="services"
                type="text"
                id="services"
                name="services"
                required={true}
                value={contactForm.services}
                onChange={handleContactFormChange}
              ></input>
              <div className="input-underline"></div>
              <label htmlFor="services">
                How can I help you? &nbsp;&#40;eg: Design&#41;
                <span className="text-red-500">&nbsp;*</span>
              </label>
            </div>
            <div className="theme-input">
              <input
                placeholder="company"
                type="text"
                id="company"
                name="company"
                value={contactForm.company}
                onChange={handleContactFormChange}
              ></input>
              <div className="input-underline"></div>
              <label htmlFor="company">Company/Organization</label>
            </div>

            <div className="theme-input textarea">
              <textarea
                placeholder="message"
                id="message"
                name="message"
                maxLength={300}
                value={contactForm.message}
                onChange={handleContactFormChange}
              ></textarea>
              <br />
              <div className="input-underline"></div>
              <label htmlFor="message">Message</label>
            </div>

            <button className="linked-btn w-max mt-5 mx-auto" type="submit">
              {status}
            </button>
            {result}
          </form>
        </div>
      </div>
      <div className="absolute top-0 left-0 h-1/3 aspect-square bg-theme-pink blur-3xl opacity-[0.03] dark:opacity-[0.06] rounded-full"></div>
      <div className="absolute bottom-[10%] right-0 h-1/3 aspect-square bg-theme-blue blur-3xl opacity-[0.06] rounded-full"></div>
    </div>
  );
};

export default ContactPage;
