import React from "react";

const HTML = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-full group-hover:scale-125 transition"
      fill="none"
      viewBox="0 0 80 80"
    >
      <path
        fill="#F67600"
        d="M68.383 10.254a1.395 1.395 0 00-1.034-.454H12.651c-.394 0-.771.164-1.034.454-.268.29-.399.678-.36 1.072l4.927 55.196c.049.58.453 1.06 1.011 1.225l22.417 6.398c.12.038.251.055.383.055.13 0 .257-.017.382-.055l22.433-6.398a1.412 1.412 0 001.012-1.225l4.922-55.196a1.38 1.38 0 00-.361-1.072zM56.587 29.285H30.249l.63 7.126h25.073l-1.88 21.06-14.083 4.255-.137-.044-13.918-4.217-.76-8.58h6.82l.295 3.33 7.782 1.63 7.64-1.63.815-9.012h-23.86l-1.854-20.727h34.382l-.607 6.809z"
      ></path>
    </svg>
  );
};

export default HTML;
