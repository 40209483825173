import React, { useEffect, useRef } from "react";
import { BaselineAnimText } from "../common/AnimatedText";
import SEOStatsDark from "../../svg/SEOStatsDark";
import SEOStatsLight from "../../svg/SEOStatsLight";
import gsap from "gsap/dist/gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
// import DrawSVGPlugin from "gsap/dist/DrawSVGPlugin";

const SEOAnim = () => {
  let seoStatsContainerRef = useRef(null);
  let stepOneRef = useRef(null);
  let stepTwoRef = useRef(null);
  let stepThreeRef = useRef(null);
  let stepFourRef = useRef(null);
  let stepFiveRef = useRef(null);

  useEffect(() => {
    let stepsHeight = document.getElementById("steps").offsetHeight;
    let chartHeight = document.getElementById("steps-chart").offsetHeight;

    gsap.registerPlugin(ScrollTrigger);
    // gsap.registerPlugin(DrawSVGPlugin);

    let seoStatsAnim1 = gsap.to(seoStatsContainerRef, {
      scrollTrigger: {
        trigger: seoStatsContainerRef,
        start: "top 20%",
        // end: () => `${stepsHeight - chartHeight}px 20%`,
        // end: `+=${stepsHeight}`,
        end: "bottom 70%",
        pin: true,
        // pinSpacing: true,
        invalidateOnRefresh: true,
      },
    });
    let seoStatsAnim2 = gsap.fromTo(
      ".point-1",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: stepOneRef,
          start: "top bottom",
          scrub: true,
        },
      }
    );
    let seoStatsAnim3 = gsap.to(
      ".chart-path",
      //   { strokeDashoffset: 546 },
      {
        strokeDashoffset: 430,
        scrollTrigger: {
          trigger: stepOneRef,
          start: "top bottom",
          scrub: true,
        },
      }
    );
    let seoStatsAnim4 = gsap.fromTo(
      ".point-2",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: stepTwoRef,
          start: "top bottom",
          scrub: true,
        },
      }
    );
    let seoStatsAnim5 = gsap.fromTo(
      ".chart-path",
      { strokeDashoffset: 430 },
      {
        strokeDashoffset: 320,
        scrollTrigger: {
          trigger: stepTwoRef,
          start: "top bottom",
          scrub: true,
        },
      }
    );
    let seoStatsAnim6 = gsap.fromTo(
      ".point-3",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: stepThreeRef,
          start: "top bottom",
          scrub: true,
        },
      }
    );
    let seoStatsAnim7 = gsap.fromTo(
      ".chart-path",
      { strokeDashoffset: 320 },
      {
        strokeDashoffset: 210,
        scrollTrigger: {
          trigger: stepThreeRef,
          start: "top bottom",
          scrub: true,
        },
      }
    );
    let seoStatsAnim8 = gsap.fromTo(
      ".point-4",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: stepFourRef,
          start: "top bottom",
          scrub: true,
        },
      }
    );
    let seoStatsAnim9 = gsap.fromTo(
      ".chart-path",
      { strokeDashoffset: 210 },
      {
        strokeDashoffset: 10,
        scrollTrigger: {
          trigger: stepFourRef,
          start: "top bottom",
          scrub: true,
        },
      }
    );
    let seoStatsAnim10 = gsap.fromTo(
      ".point-5",
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: stepFiveRef,
          start: "top bottom",
          scrub: true,
        },
      }
    );

    return () => {
      seoStatsAnim1.kill();
      seoStatsAnim2.kill();
      seoStatsAnim3.kill();
      seoStatsAnim4.kill();
      seoStatsAnim5.kill();
      seoStatsAnim6.kill();
      seoStatsAnim7.kill();
      seoStatsAnim8.kill();
      seoStatsAnim9.kill();
      seoStatsAnim10.kill();
    };
  });
  return (
    <div className="relative py-0 w-full flex flex-col lg:flex-row justify-between pt-1 overflow-hidden">
      <div
        id="steps-chart"
        className="relative seo_stats w-full py-20 lg:w-1/2 lg:px-20"
        ref={(el) => (seoStatsContainerRef = el)}
      >
        <div className="relative opacity-0 lg:opacity-100 dark:hidden">
          <SEOStatsLight />
          <div className="steps_chart-blue-blob"></div>
          <div className="steps_chart-pink-blob"></div>
        </div>
        <div className="relative opacity-0 lg:opacity-100 hidden dark:flex w-full">
          <SEOStatsDark />
          <div className="steps_chart-blue-blob"></div>
          <div className="steps_chart-pink-blob"></div>
        </div>
      </div>
      <div
        id="steps"
        className="absolute top-0 left-0 lg:relative w-full lg:w-1/2 flex flex-col z-10 lg:pt-20"
      >
        <div
          className="steps_text-container step01"
          ref={(el) => (stepOneRef = el)}
        >
          <div className="steps_text-numbering">01</div>
          <div className="steps_text-right">
            <h3 className="steps_text-heading">
              <BaselineAnimText text="Keyword Research" />
            </h3>
            <div className="steps_text-paragraph">
              <BaselineAnimText text="Keywords are the words your audience types into search engines. I use tools like Google Keyword Planner to find the right ones and also sprinkle them naturally into your content." />
            </div>
          </div>
        </div>
        <div
          className="steps_text-container step02"
          ref={(el) => (stepTwoRef = el)}
        >
          <div className="steps_text-numbering">02</div>
          <div className="steps_text-right">
            <h3 className="steps_text-heading">
              <BaselineAnimText text="On-Page SEO" />
            </h3>
            <div className="steps_text-paragraph">
              <BaselineAnimText
                text="On-page SEO is like giving your website a clear voice. I help you optimize your titles, meta descriptions, and headers with relevant keywords. This helps search engines understand your content.
"
              />
            </div>
          </div>
        </div>
        <div
          className="steps_text-container step03"
          ref={(el) => (stepThreeRef = el)}
        >
          <div className="steps_text-numbering">03</div>
          <div className="steps_text-right">
            <h3 className="steps_text-heading">
              <BaselineAnimText text="Quality Backlinks" />
            </h3>
            <div className="steps_text-paragraph">
              <BaselineAnimText text="Backlinks are like recommendations. I seek links from reputable sites to boost your credibility. Quality over quantity is the key." />
            </div>
          </div>
        </div>
        <div
          className="steps_text-container step04"
          ref={(el) => (stepFourRef = el)}
        >
          <div className="steps_text-numbering">04</div>
          <div className="steps_text-right">
            <h3 className="steps_text-heading">
              <BaselineAnimText text="Social Media Presence" />
            </h3>
            <div className="steps_text-paragraph">
              <BaselineAnimText text="Social media is like a bustling town square and I'll help you stand out! I can share your content, engage with your audience, and also encourage sharing." />
            </div>
          </div>
        </div>
        <div
          className="steps_text-container step05"
          ref={(el) => (stepFiveRef = el)}
        >
          <div className="steps_text-numbering">05</div>
          <div className="steps_text-right">
            <h3 className="steps_text-heading">
              <BaselineAnimText text="Local SEO" />
            </h3>
            <div className="steps_text-paragraph">
              <BaselineAnimText text="If your business caters to local customers, you need to ensure your local SEO game is strong and I'm here to help you with it." />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SEOAnim;
