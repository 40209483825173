import React from "react";
import CaseStudyTemplate from "../../components/porfoliopage/CaseStudyTemplate";
import pageData from "../../data/caseStudy/blackhills.json";

const BlackHills = () => {
  return (
    <>
      <CaseStudyTemplate pageData={pageData} />
    </>
  );
};

export default BlackHills;
