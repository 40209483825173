import React, { useEffect, useRef } from "react";
import { BaselineAnimText } from "../common/AnimatedText";
import SEOAnim from "./SEOAnim";

function SEO() {
  return (
    <div className="min-h-screen center">
      <div className="main-container flex flex-col justify-center lg:items-center pt-0 pb-20 lg:py-32">
        <h2 className="theme-h1 font-semibold pb-5 lg:pb-10  lg:text-center">
          <BaselineAnimText text="03. SEO" />
        </h2>
        <div className="theme-p1  lg:max-w-[600px] pb-5 lg:pb-0 lg:text-center">
          <BaselineAnimText text="Having a stunning website is essential, but it's equally important to ensure it's easily discoverable." />
        </div>

        {/* animated section -------------------------------------------------------------- */}
        <div className="hidden lg:flex w-full">
          <SEOAnim />
        </div>

        <div className="relative py-0 lg:py-20 w-full flex flex-col lg:flex-row justify-between pt-1 lg:hidden">
          <div
            id="steps"
            className=" w-full lg:w-1/2 flex flex-col max-h-[200vh] z-10 lg:pt-20"
          >
            <div className="steps_text-container step01">
              <div className="steps_text-numbering">01</div>
              <div className="steps_text-right">
                <h3 className="steps_text-heading">
                  <BaselineAnimText text="Keyword Research" />
                </h3>
                <p className="steps_text-paragraph">
                  <BaselineAnimText text="Keywords are the words your audience types into search engines. I use tools like Google Keyword Planner to find the right ones and also sprinkle them naturally into your content." />
                </p>
              </div>
            </div>
            <div className="steps_text-container step02">
              <div className="steps_text-numbering">02</div>
              <div className="steps_text-right">
                <h3 className="steps_text-heading">
                  <BaselineAnimText text="On-Page SEO" />
                </h3>
                <p className="steps_text-paragraph">
                  <BaselineAnimText
                    text="On-page SEO is like giving your website a clear voice. I help you optimize your titles, meta descriptions, and headers with relevant keywords. This helps search engines understand your content.
"
                  />
                </p>
              </div>
            </div>
            <div className="steps_text-container step03">
              <div className="steps_text-numbering">03</div>
              <div className="steps_text-right">
                <h3 className="steps_text-heading">
                  <BaselineAnimText text="Quality Backlinks" />
                </h3>
                <p className="steps_text-paragraph">
                  <BaselineAnimText text="Backlinks are like recommendations. I seek links from reputable sites to boost your credibility. Quality over quantity is the key." />
                </p>
              </div>
            </div>
            <div className="steps_text-container step04">
              <div className="steps_text-numbering">04</div>
              <div className="steps_text-right">
                <h3 className="steps_text-heading">
                  <BaselineAnimText text="Social Media Presence" />
                </h3>
                <p className="steps_text-paragraph">
                  <BaselineAnimText text="Social media is like a bustling town square and I'll help you stand out! I can share your content, engage with your audience, and also encourage sharing." />
                </p>
              </div>
            </div>
            <div className="steps_text-container step05">
              <div className="steps_text-numbering">05</div>
              <div className="steps_text-right">
                <h3 className="steps_text-heading">
                  <BaselineAnimText text="Local SEO" />
                </h3>
                <p className="steps_text-paragraph">
                  <BaselineAnimText text="If your business caters to local customers, you need to ensure your local SEO game is strong and I'm here to help you with it." />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SEO;
