import React from "react";
import CaseStudyTemplate from "../../components/porfoliopage/CaseStudyTemplate";
import pageData from "../../data/caseStudy/zalchileo.json";

const Zalchileo = () => {
  return (
    <>
      <CaseStudyTemplate pageData={pageData} />
    </>
  );
};

export default Zalchileo;
