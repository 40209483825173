import React from 'react'
import CaseStudyTemplate from '../../components/porfoliopage/CaseStudyTemplate'
import pageData from "../../data/caseStudy/blukers.json"

const Blukers = () => {
  return (
    <>
    <CaseStudyTemplate pageData={pageData}/>
    </>
  )
}

export default Blukers