import React from "react";
import CaseStudyTemplate from "../../components/porfoliopage/CaseStudyTemplate";
import pageData from "../../data/caseStudy/learn2soar.json";

const Learn2Soar = () => {
  return (
    <>
      <CaseStudyTemplate pageData={pageData} />
    </>
  );
};

export default Learn2Soar;
