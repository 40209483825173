import React from "react";
import { motion } from "framer-motion";
import featProjectsData from "../data/feat-projects.json";
import SingleProjectList from "../components/porfoliopage/SingleProjectList";

const ProjectsPage = () => {
  return (
    <div className="bg-theme-white text-theme-black dark:bg-theme-black dark:text-theme-white">
      <div className="main-container pb-12 pt-32 lg:pt-60">
        <div className="text-center theme-lgtext flex flex-col items-center font-semibold">
          <h1 className="-mt-[1rem] lg:-mt-[2rem] overflow-hidden">
            <motion.div
              initial={{ y: "80%" }}
              whileInView={{ y: 0 }}
              transition={{ ease: [0.455, 0.03, 0.515, 0.955], duration: 0.3 }}
              className="py-[0.75rem]"
            >
              P<span className="theme-italic">r</span>ojects
            </motion.div>
          </h1>
        </div>

        {/* list of projects --------------------------------------------------------------------------------------------------- */}
        <div className="projects_list-wrapper">
          {/* {featProjectsData.map((item, index) => (
            <SingleProjectList
              key={index}
              imgSrc={item.imgSrc}
              link={item.link}
              title={item.name}
              categories={item.category}
            />
          ))} */}
          <div className="test lg:col-span-7">
            <SingleProjectList
              imgSrc={featProjectsData[0].imgSrc}
              lgImgSrc={featProjectsData[0].lgImgSrc}
              link={featProjectsData[0].link}
              title={featProjectsData[0].name}
              categories={featProjectsData[0].category}
              // darkText={true}
            />
          </div>
          <div className="test lg:col-span-5">
            <SingleProjectList
              imgSrc={featProjectsData[1].imgSrc}
              lgImgSrc={featProjectsData[1].lgImgSrc}
              link={featProjectsData[1].link}
              title={featProjectsData[1].name}
              categories={featProjectsData[1].category}
              // darkText={true}
            />
          </div>
          <div className="sub-grid lg:col-span-4 grid grid-cols-1 gap-5 lg:gap-8 ">
            <SingleProjectList
              imgSrc={featProjectsData[2].imgSrc}
              lgImgSrc={featProjectsData[2].lgImgSrc}
              link={featProjectsData[2].link}
              title={featProjectsData[2].name}
              categories={featProjectsData[2].category}
              // darkText={true}
            />
            <SingleProjectList
              imgSrc={featProjectsData[3].imgSrc}
              lgImgSrc={featProjectsData[3].lgImgSrc}
              link={featProjectsData[3].link}
              title={featProjectsData[3].name}
              categories={featProjectsData[3].category}
              darkText={true}
            />
          </div>
          <div className="test lg:col-span-8">
            <SingleProjectList
              imgSrc={featProjectsData[4].imgSrc}
              lgImgSrc={featProjectsData[4].lgImgSrc}
              link={featProjectsData[4].link}
              title={featProjectsData[4].name}
              categories={featProjectsData[4].category}
            />
          </div>
          <div className="test lg:col-span-7">
            <SingleProjectList
              imgSrc={featProjectsData[5].imgSrc}
              lgImgSrc={featProjectsData[5].lgImgSrc}
              link={featProjectsData[5].link}
              title={featProjectsData[5].name}
              categories={featProjectsData[5].category}
              darkText={true}
            />
          </div>
          <div className="test lg:col-span-5">
            <SingleProjectList
              imgSrc={featProjectsData[6].imgSrc}
              lgImgSrc={featProjectsData[6].lgImgSrc}
              link={featProjectsData[6].link}
              title={featProjectsData[6].name}
              categories={featProjectsData[6].category}
              darkText={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
