import React from "react";
import CaseStudyTemplate from "../../components/porfoliopage/CaseStudyTemplate";
import pageData from "../../data/caseStudy/ideamood.json";

const IdeaMood = () => {
  return (
    <>
      <CaseStudyTemplate pageData={pageData} />
    </>
  );
};

export default IdeaMood;
