import React, { useRef, useEffect } from "react";
import { BaselineAnimText } from "../common/AnimatedText";
import { useParallax } from "react-scroll-parallax";
import { motion } from "framer-motion";

const Design = () => {
  let parallax = useParallax({
    speed: -30,
  });
  let block1parallax = useParallax({
    speed: -10,
  });
  let block1parallaxLight = useParallax({
    speed: -10,
  });
  let block2parallax = useParallax({
    speed: 2,
  });
  let block2parallaxLight = useParallax({
    speed: 2,
  });
  let block5parallax = useParallax({
    speed: 2,
  });
  let block5parallaxLight = useParallax({
    speed: 2,
  });

  let down1parallax = useParallax({
    speed: 5,
  });
  let down2parallax = useParallax({
    speed: -8,
  });
  let down3parallax = useParallax({
    speed: 6,
  });
  let down4parallax = useParallax({
    speed: 1,
  });
  let down1parallaxLight = useParallax({
    speed: 5,
  });
  let down2parallaxLight = useParallax({
    speed: -8,
  });
  let down3parallaxLight = useParallax({
    speed: 6,
  });
  let down4parallaxLight = useParallax({
    speed: 1,
  });

  return (
    <div
      className="py-20 lg:py-32 relative min-h-[80vh] lg:min-h-screen center overflow-hidden before:content[''] before:absolute
    before:inset-0 before:z-[1] before:block before:bg-gradient-to-t before:from-theme-white dark:before:from-theme-black before:to-transparent"
    >
      <div
        className="absolute top-0 left-0 w-full h-full bg-[url('https://res.cloudinary.com/dkusl18gf/image/upload/v1722597611/eriiikaaa/feat-projects/day_sky_rcznai.avif')] dark:bg-[url('https://res.cloudinary.com/dkusl18gf/image/upload/v1722597612/eriiikaaa/feat-projects/dark_night_uyqsli.avif')] bg-cover bg-no-repeat bg-center"
        ref={parallax.ref}
      ></div>
      <div className="relative z-20 w-full">
      <div className="main-container">
        <h2 className="theme-h1 font-semibold pb-5 lg:pb-10">
          <BaselineAnimText text="02. Design" />
        </h2>
        <p className="theme-p1 lg:max-w-[1000px] pb-12 lg:pb-32">
          <BaselineAnimText text="Let's face it, aesthetics matter, and that's why I'm here to weave some website magic that oozes your brand's personality and leaves users dazzled. Whether you need a total revamp or a quick spruce-up, I'm your go-to person for all your web design and UX/UI design needs!" />
        </p>
      </div>
      <div className="lg:hidden grid grid-cols-12 pb-20">
        <div className="col-span-1"></div>
        <motion.img
          initial={{ y: "50%", rotate: 0 }}
          whileInView={{ y: 0, rotate: "-3deg" }}
          src="/img/dark-mockup/Slider.png"
          className="col-span-8"
          alt="mockup"
        />
        <div className="col-span-3"></div>
        <div className="col-span-4"></div>
        <motion.img
          initial={{ y: "50%", rotate: 0 }}
          whileInView={{ y: 0, rotate: "3deg" }}
          src="/img/dark-mockup/Pet.png"
          className="col-span-8 -mt-8 rotate-3"
          alt="mockup"
        />
        <div className="col-span-2"></div>
        <motion.img
          initial={{ y: "50%", rotate: 0 }}
          whileInView={{ y: 0, rotate: "-3deg" }}
          src="/img/dark-mockup/Lang.png"
          className="col-span-3 -mt-24 -rotate-3"
          alt="mockup"
        />
      </div>
      <div className="mockups relative hidden lg:flex lg:min-h-[150vh] pt-20 ">
        {/* dark  */}
        <img
          src="/img/dark-mockup/Wed.png"
          className="device_block_1 hidden dark:flex w-[45%] absolute right-0 left-[8%] mx-auto"
          alt="mockup"
          ref={block1parallax.ref}
        />
        <img
          src="/img/dark-mockup/Bluk.png"
          className="device_block_2 hidden dark:flex w-[35%] absolute top-[2%] -left-[10%] mx-auto"
          alt="mockup"
          ref={block2parallax.ref}
        />
        <img
          src="/img/dark-mockup/Raf.png"
          className="device_block_3 hidden dark:flex w-[32%] absolute top-[8%] -right-[13%] mx-auto"
          alt="mockup"
        />
        <img
          src="/img/dark-mockup/Lang.png"
          className="device_block_4 hidden dark:flex w-[10%] absolute top-[18%] right-[20%] mx-auto"
          alt="mockup"
        />
        <img
          src="/img/dark-mockup/Pet.png"
          className="device_block_5 hidden dark:flex w-[29%] absolute top-[20%] left-[18%] mx-auto"
          alt="mockup"
          ref={block5parallax.ref}
        />
        <img
          src="/img/dark-mockup/Kine.png"
          className="device_block_down_1 hidden dark:flex w-[37%] absolute top-[58%] -left-[10%] mx-auto z-10"
          alt="mockup"
          ref={down1parallax.ref}
        />
        <img
          src="/img/dark-mockup/Slider.png"
          className="device_block_down_2 hidden dark:flex w-[37%] absolute top-[63%] left-[28%] mx-auto z-10"
          alt="mockup"
          ref={down2parallax.ref}
        />
        <img
          src="/img/dark-mockup/Arch.png"
          className="device_block_down_3 hidden dark:flex w-[18%] absolute top-[58%] right-[17%] mx-auto z-10"
          alt="mockup"
          ref={down3parallax.ref}
        />
        <img
          src="/img/dark-mockup/Zal.png"
          className="device_block_down_2 hidden dark:flex w-[37%] absolute top-[55%] -right-[20%] mx-auto z-10"
          alt="mockup"
          ref={down4parallax.ref}
        />
        {/* light */}
        <img
          src="/img/mockup/Wed.png"
          className="device_block_1 dark:hidden w-[45%] absolute right-0 left-[8%] mx-auto"
          alt="mockup"
          ref={block1parallaxLight.ref}
        />
        <img
          src="/img/mockup/Bluk.png"
          className="device_block_2 dark:hidden w-[35%] absolute top-[2%] -left-[10%] mx-auto"
          alt="mockup"
          ref={block2parallaxLight.ref}
        />
        <img
          src="/img/mockup/Raf.png"
          className="device_block_3 dark:hidden w-[32%] absolute top-[8%] -right-[13%] mx-auto"
          alt="mockup"
        />
        <img
          src="/img/mockup/Lang.png"
          className="device_block_4 dark:hidden w-[10%] absolute top-[18%] right-[20%] mx-auto"
          alt="mockup"
        />
        <img
          src="/img/mockup/Pet.png"
          className="device_block_5 dark:hidden w-[29%] absolute top-[20%] left-[18%] mx-auto"
          alt="mockup"
          ref={block5parallaxLight.ref}
        />
        <img
          src="/img/mockup/Kine.png"
          className="device_block_down_1 dark:hidden w-[37%] absolute top-[58%] -left-[10%] mx-auto z-10"
          alt="mockup"
          ref={down1parallaxLight.ref}
        />
        <img
          src="/img/mockup/Slider.png"
          className="device_block_down_2 dark:hidden w-[37%] absolute top-[63%] left-[28%] mx-auto z-10"
          alt="mockup"
          ref={down2parallaxLight.ref}
        />
        <img
          src="/img/mockup/Arch.png"
          className="device_block_down_3 dark:hidden w-[18%] absolute top-[58%] right-[17%] mx-auto z-10"
          alt="mockup"
          ref={down3parallaxLight.ref}
        />
        <img
          src="/img/mockup/Zal.png"
          className="device_block_down_2 dark:hidden w-[37%] absolute top-[55%] -right-[20%] mx-auto z-10"
          alt="mockup"
          ref={down4parallaxLight.ref}
        />
      </div></div>
    </div>
  );
};

export default Design;
