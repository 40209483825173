import React from 'react'
import Intro from '../components/homepage/Intro'
import Development from '../components/homepage/Development'
import Design from '../components/homepage/Design'
import SEO from '../components/homepage/SEO'
import SEOStatsLight from '../svg/SEOStatsLight'

const HomePage = () => {
  return (
    <>
    <div className='bg-theme-white text-theme-black dark:bg-theme-black dark:text-theme-white'>
      {/* <div className='scroller'> */}
      <Intro />
      {/* <section className='w-screen h-[80vh] bg-red-200'></section> */}
      {/* </div> */}
      <Development />
      <Design />
      <SEO />
    </div>
    </>
  )
}

export default HomePage