import React from "react";

const ReactLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-full group-hover:scale-125 transition"
      fill="none"
      viewBox="0 0 80 80"
    >
      <path
        fill="#149ECA"
        d="M40.5 47.148c3.921 0 7.1-3.2 7.1-7.15 0-3.948-3.179-7.148-7.1-7.148s-7.1 3.2-7.1 7.149c0 3.948 3.179 7.15 7.1 7.15z"
      ></path>
      <path
        stroke="#149ECA"
        d="M40.5 56.086C60.106 56.086 76 48.884 76 40c0-8.884-15.894-16.086-35.5-16.086S5 31.116 5 40c0 8.884 15.894 16.086 35.5 16.086z"
      ></path>
      <path
        stroke="#149ECA"
        d="M26.665 48.043C36.468 65.14 50.61 75.399 58.25 70.957c7.64-4.442 5.888-21.903-3.915-39S30.39 4.601 22.75 9.043c-7.64 4.442-5.888 21.903 3.915 39z"
      ></path>
      <path
        stroke="#149ECA"
        d="M26.665 31.957c-9.803 17.097-11.556 34.558-3.915 39 7.64 4.442 21.782-5.817 31.585-22.914s11.556-34.558 3.915-39c-7.64-4.442-21.782 5.817-31.585 22.914z"
      ></path>
    </svg>
  );
};

export default ReactLogo;
